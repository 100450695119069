import moment from 'moment'

export const canHavePayment = medicalPayment => {
  if (!medicalPayment) return false
  return Boolean(
    !(
      (medicalPayment.startHospitalizationFlg === 1 ||
        medicalPayment.inHospitalFlg === 1) &&
      medicalPayment.endHospitalizationFlg === 0 &&
      medicalPayment.middleCalculateFlg === 0
    )
  )
}

export const isHospitalization = medicalPayment => {
  return Boolean(
    medicalPayment.startHospitalizationFlg === 1 ||
      medicalPayment.inHospitalFlg === 1 ||
      medicalPayment.endHospitalizationFlg === 1
  )
}

export const isStartEnd = medicalPayment => {
  return Boolean(
    medicalPayment.startHospitalizationFlg === 1 &&
      medicalPayment.inHospitalFlg === 0 &&
      medicalPayment.endHospitalizationFlg === 1
  )
}

export const isStart = medicalPayment => {
  return Boolean(
    medicalPayment.startHospitalizationFlg === 1 &&
      medicalPayment.inHospitalFlg === 0 &&
      medicalPayment.endHospitalizationFlg === 0
  )
}

export const isIn = medicalPayment => {
  return Boolean(
    medicalPayment.startHospitalizationFlg === 0 &&
      medicalPayment.inHospitalFlg === 1 &&
      medicalPayment.endHospitalizationFlg === 0
  )
}

export const isEnd = medicalPayment => {
  return Boolean(
    medicalPayment.startHospitalizationFlg === 0 &&
      medicalPayment.inHospitalFlg === 0 &&
      medicalPayment.endHospitalizationFlg === 1
  )
}

export const hasSameStartEnd = hospitalization => {
  const {
    startMedicalPaymentOriginalId,
    endMedicalPaymentOriginalId
  } = hospitalization
  const hasSameStartEnd =
    startMedicalPaymentOriginalId &&
    endMedicalPaymentOriginalId &&
    startMedicalPaymentOriginalId === endMedicalPaymentOriginalId
  return hasSameStartEnd
}

export const hasIn = hospitalization => {
  const {
    startMedicalPaymentOriginalId,
    medicalPaymentOriginalIds,
    endMedicalPaymentOriginalId
  } = hospitalization
  const hasIn = medicalPaymentOriginalIds.some(
    v =>
      startMedicalPaymentOriginalId &&
      endMedicalPaymentOriginalId &&
      v !== startMedicalPaymentOriginalId &&
      v !== endMedicalPaymentOriginalId
  )
  return hasIn
}

export const hasEnd = hospitalization => {
  const { endMedicalPaymentOriginalId } = hospitalization
  const hasEnd =
    endMedicalPaymentOriginalId && endMedicalPaymentOriginalId !== 0
  return hasEnd
}

export const makeHospitalizationPeriods = hospitalizationData => {
  let startPeriod, endPeriod
  let inPeriods = []
  hospitalizationData.forEach(v => {
    if (v.startHospitalizationFlg && v.endHospitalizationFlg) return
    const startDateTime = v.date + v.startTime
    const endDateTime = v.date + v.endTime
    const { medicalPaymentOriginalId } = v
    const value = { medicalPaymentOriginalId, startDateTime, endDateTime }
    if (v.startHospitalizationFlg) startPeriod = value
    if (v.endHospitalizationFlg) endPeriod = value
    if (v.inHospitalFlg) inPeriods.push(value)
  })
  return { startPeriod, endPeriod, inPeriods }
}

export const makeInEndDateTime = hospitalizationData => {
  let inEndDateTime = ''
  hospitalizationData.forEach(v => {
    if (v.endHospitalizationFlg) return
    const endDateTime = v.date + v.endTime
    if (inEndDateTime === '') {
      inEndDateTime = endDateTime
    } else {
      if (inEndDateTime < endDateTime) {
        inEndDateTime = endDateTime
      }
    }
  })
  return inEndDateTime
}

const toHHmm = dateTime => {
  return moment(dateTime, 'YYYYMMDDHHmm').format('HHmm')
}

const toYYYYMMDD = dateTime => {
  return moment(dateTime, 'YYYYMMDDHHmm').format('YYYYMMDD')
}

const toStartTime = (period, mrDate) => {
  const date = period ? toYYYYMMDD(period.startDateTime) : ''
  const time = period ? toHHmm(period.startDateTime) : ''
  const startTime = mrDate === date ? time : ''
  return startTime
}

const toEndTime = (period, mrDate) => {
  const date = period ? toYYYYMMDD(period.endDateTime) : ''
  const time = period ? toHHmm(period.endDateTime) : ''
  const endTime = mrDate === date ? time : ''
  return endTime
}

export const startEndMinMax = (
  medicalRecord,
  medicalPayment,
  hospitalizationData,
  previousHospitalizationPeriod,
  nextHospitalizationPeriod,
  initialMedicalPayment
) => {
  let startTimeMin = ''
  let startTimeMax = ''
  let endTimeMin = ''
  let endTimeMax = ''
  const mrDate = medicalRecord.date
  const mrStartDateTime = medicalRecord.date + medicalRecord.startTime
  const mrEndDateTime = medicalRecord.date + medicalRecord.endTime
  if (previousHospitalizationPeriod) {
    startTimeMin = toEndTime(previousHospitalizationPeriod, mrDate)
  }
  if (nextHospitalizationPeriod) {
    endTimeMax = toStartTime(nextHospitalizationPeriod, mrDate)
  }
  if (hospitalizationData.length > 0) {
    const { startPeriod, endPeriod, inPeriods } = makeHospitalizationPeriods(
      hospitalizationData
    )
    let inPeriod
    inPeriods.forEach(v => {
      if (v.medicalPaymentOriginalId === medicalPayment.originalId) return
      const { startDateTime, endDateTime } = v
      if (!inPeriod) {
        inPeriod = { startDateTime, endDateTime }
      } else {
        if (startDateTime < inPeriod.startDateTime) {
          inPeriod.startDateTime = startDateTime
        }
        if (inPeriod.endDateTime < endDateTime) {
          inPeriod.endDateTime = endDateTime
        }
      }
    })
    if (isStart(medicalPayment)) {
      const period = inPeriod || endPeriod
      endTimeMax = toStartTime(period, mrDate)
    }
    if (isIn(medicalPayment)) {
      let previousInPeriod, nextInPeriod
      inPeriods.forEach(inPeriodOne => {
        //新規の入院中の明細の場合は入院開始～退院の時間の範囲で時間を選択できるようにする
        if (
          !medicalPayment.id ||
          inPeriodOne.medicalPaymentOriginalId === medicalPayment.originalId ||
          !isHospitalization(initialMedicalPayment) //既存の外来の診療明細で入院中にチェックした場合は入院開始～退院の時間の範囲で時間を選択できるようにする
        ) {
          return
        }
        if (inPeriodOne.endDateTime <= mrStartDateTime) {
          if (
            !previousInPeriod ||
            inPeriodOne.endDateTime > previousInPeriod.endDateTime
          ) {
            previousInPeriod = inPeriodOne
          }
        }
        if (mrEndDateTime <= inPeriodOne.startDateTime) {
          if (
            !nextInPeriod ||
            inPeriodOne.startDateTime < nextInPeriod.startDateTime
          ) {
            nextInPeriod = inPeriodOne
          }
        }
      })
      if (previousInPeriod) {
        startTimeMin = toEndTime(previousInPeriod, mrDate)
      } else {
        startTimeMin = toEndTime(startPeriod, mrDate)
      }
      //endTimeMaxを調整するロジック
      //新規診療明細を作成する場合
      if (!medicalPayment.id) {
        if (endPeriod && mrStartDateTime < endPeriod.startDateTime) {
          //入院中の診療明細を追加と判断
          endTimeMax = toStartTime(endPeriod, mrDate)
        } else {
          //明細追加、退院明細がない場合
          endTimeMax = toEndTime(endPeriod, mrDate)
        }
      } else {
        //既存の診療明細を開く
        if (nextInPeriod) {
          //入院中の診療明細が二つ以上
          endTimeMax = toStartTime(nextInPeriod, mrDate)
        } else if (
          endPeriod &&
          medicalPayment.originalId !== endPeriod.medicalPaymentOriginalId
        ) {
          endTimeMax = toStartTime(endPeriod, mrDate)
        }
      }
    }
    if (isEnd(medicalPayment)) {
      const startPeriodEnd = startPeriod ? startPeriod.endDateTime : '0'
      const inPeriodEnd = inPeriod ? inPeriod.endDateTime : '0'
      const periodForEnd = startPeriodEnd < inPeriodEnd ? inPeriod : startPeriod
      startTimeMin = toEndTime(periodForEnd, mrDate)
    }
  }
  const obj = { startTimeMin, startTimeMax, endTimeMin, endTimeMax }
  return obj
}
