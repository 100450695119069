<template>
  <div class="owner-patient-detail">
    <div class="detail owner">
      <div class="item show-id">
        <div class="label">飼主ID</div>
        <div class="value show-id">{{ displayOwner.showId }}</div>
      </div>
      <div class="item full-name">
        <div class="label">氏 名</div>
        <div class="value emphasis" :title="displayOwner.fullName">
          {{ displayOwner.fullName }}
        </div>
      </div>
      <div class="item tel">
        <div class="label">TEL</div>
        <div class="value">{{ displayOwner.tel }}</div>
      </div>
      <div class="item full-address" v-if="addressFlg">
        <div class="label"><img src="@/assets/images/home.png" /></div>
        <div class="value" :title="displayOwner.fullAddress">
          {{ displayOwner.fullAddress }}
        </div>
      </div>
    </div>
    <div class="detail patient">
      <div class="item image" v-if="patientImageFlg">
        <img v-if="displayPatient.image" :src="displayPatient.image" />
        <img v-else src="@/assets/images/photo.png" />
      </div>
      <div class="item show-id">
        <div class="label">患者ID</div>
        <div class="value show-id">{{ displayPatient.showId }}</div>
      </div>
      <div class="item name">
        <div class="label">なまえ</div>
        <div class="value" :title="displayPatient.name">
          <span class="emphasis">{{ displayPatient.name }}</span>
        </div>
      </div>
      <div
        class="item age-birthday"
        v-if="displayPatient.age !== '' && displayPatient.birthday !== ''"
      >
        <div class="value">
          <span class="emphasis">{{ displayPatient.age }}</span> /
          {{ displayPatient.birthday }}
        </div>
      </div>
      <div class="item species">
        <div class="label">種類</div>
        <div
          class="value"
          :title="`${displayPatient.speciesName}/${displayPatient.breed}`"
        >
          <span class="emphasis">{{ displayPatient.speciesName }}</span> /
          {{ displayPatient.breed }}
        </div>
      </div>
      <div class="item sex-name">
        <div class="label">性別</div>
        <div class="value">{{ displayPatient.sexName }}</div>
      </div>
      <div class="item last-treatment-date" v-if="lastTreatmentDateFlg">
        <div class="label">最終診療日</div>
        <div class="value">{{ lastTreatmentDate }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { makeBirthday, calcAge } from '@/utils/patient_info'

export default {
  name: 'OwnerPatientDetail',

  props: {
    owner: { type: Object, required: true },
    patient: { type: Object, required: true },
    addressFlg: { type: Boolean, default: true },
    patientImageFlg: { type: Boolean, default: true },
    lastTreatmentDateFlg: { type: Boolean, default: true }
  },

  computed: {
    ...mapGetters({
      getMasterDatum: 'master/getDataById',
      getMedicalRecordsByPatientId: 'medicalRecords/getDataByPatientId'
    }),
    displayOwner() {
      const owner = this.owner
      if (owner) {
        const prefName =
          this.getMasterDatum('prefectures', owner.prefectureId)?.name || ''
        const fullAddress = prefName + owner.address + owner.building
        return {
          ...owner,
          fullName: `${owner.lastName} ${owner.firstName}`,
          fullAddress
        }
      } else {
        return {}
      }
    },
    displayPatient() {
      const patient = this.patient
      if (patient) {
        const patientSexes = this.$store.getters[
          'master/selectPatientSexesZero'
        ]
        const speciesName =
          this.$store.getters['species/getDataById'](patient.speciesId)?.name ||
          ''
        return {
          ...patient,
          age: calcAge(patient),
          birthday: makeBirthday(patient),
          speciesName,
          sexName: patientSexes.find(v => v.id === patient.sex).name
        }
      } else {
        return {}
      }
    },
    lastTreatmentDate() {
      const records = this.getMedicalRecordsByPatientId(this.patient?.id)
      let lastDate = ''
      records?.forEach(r => {
        if (r.date > lastDate && r.date <= moment().format('YYYYMMDD')) {
          lastDate = r.date
        }
      })
      return lastDate
        ? moment(lastDate, 'YYYYMMDD').format('YYYY年MM月DD日（dd）')
        : '未診療'
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #{$light-grey};
.owner-patient-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  box-sizing: border-box;
  border: solid 1px $border-color;
  background-color: #{$very-light-shade-brown};
  padding: 0 15px;
  height: 105px;
  > .detail {
    display: flex;
    height: 100%;
    &.owner {
      font-size: 16px;
      > .item {
        &.show-id {
          max-width: 200px;
        }
        &.full-name {
          flex: 1;
          width: 0;
          max-width: fit-content;
        }
        &.tel {
          max-width: 160px;
        }
        &.full-address {
          flex: 1;
          width: 0;
          max-width: fit-content;
        }
      }
    }
    &.patient {
      font-size: 12px;
      > .item {
        &.image {
          width: 40px;
        }
        &.show-id {
          max-width: 200px;
        }
        &.name {
          flex: 1;
          width: 0;
          max-width: fit-content;
        }
        &.age-birthday {
          max-width: 175px;
        }
        &.species {
          flex: 1;
          width: 0;
          max-width: fit-content;
        }
        &.sex-name {
          max-width: 65px;
        }
        &.last-treatment-date {
          max-width: 215px;
        }
      }
    }
    > .item {
      display: flex;
      align-items: center;
      &.image {
        padding-right: 10px;
      }
      > .label {
        margin-right: 10px;
        font-size: 11px;
        > img {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      > .value {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 20px;
        &.show-id {
          font-weight: bold;
          font-size: 11px;
        }
      }
      > img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
      }
      &:last-child {
        > .value {
          padding-right: 0;
        }
      }
    }
  }
  > .detail ~ .detail {
    border-top: solid 1px $border-color;
  }
}
.emphasis {
  font-weight: bold;
  font-size: 16px;
}
</style>
