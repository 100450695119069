<template>
  <div class="hospitalizations">
    <base-loading :waitFlg="waitFlg" />
    <div class="wrap">
      <div class="area page-title">
        入院
      </div>
      <div class="area search">
        <search-area
          :ref="'searchArea'"
          :textBoxLabel="'検索単語'"
          :textValue="searchText"
          :placeholder="'飼主ID、飼主名、患者ID、患者名'"
          :periodFlg="true"
          :periodLabel="'入院期間'"
          :periodClearFlg="false"
          :defaultStartDate="defaultStartDate"
          :defaultEndDate="defaultEndDate"
          :searchButtonFlg="true"
          :waitFlg="waitFlg"
          @input="inputSearchText"
          @input-start-date="inputDate($event, 'start')"
          @input-end-date="inputDate($event, 'end')"
          @search="resetAndGetData"
        />
      </div>
      <div class="area order">
        <div class="order-result">
          検索結果：{{ searchedHospitalizations.length }}件
        </div>
        <div class="order-setting">
          <div class="label">並び替え：</div>
          <base-select-box
            class="select-box"
            :selectData="sortTypes"
            v-model="sortType"
          />
          <base-select-box
            class="select-box"
            :selectData="orderTypes"
            v-model="orderType"
          />
        </div>
      </div>
      <div class="area list">
        <hospitalization-list-table
          :hospitalizations="orderedHospitalizations"
          @openPopup="openHospitalizationPopup"
        />
      </div>
      <hospitalization-popup
        v-if="recordListPopupFlg"
        v-bind="hospitalization"
        @close="closeHospitalizationPopup"
      ></hospitalization-popup>
    </div>
  </div>
</template>

<script>
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import SearchArea from '@/components/parts/molecules/SearchArea'
import HospitalizationListTable from '@/components/parts/organisms/HospitalizationListTable'
import HospitalizationPopup from '@/components/popups/HospitalizationPopup'
import { kataToHira } from '@/utils/convert_string'
import { compareShowId } from '@/utils/sort_show_id'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'Hospitalizations',

  components: {
    BaseLoading,
    BaseSelectBox,
    SearchArea,
    HospitalizationListTable,
    HospitalizationPopup
  },

  data() {
    return {
      searchText: '',
      startDate: '',
      endDate: '',
      sortType: 0,
      sortTypes: [
        { id: 0, name: '飼主ID順' },
        { id: 1, name: '飼主名順' },
        { id: 2, name: '患者ID順' },
        { id: 3, name: '患者名順' },
        { id: 4, name: '入院日順' },
        { id: 5, name: '退院日順' }
      ],
      orderType: 0,
      orderTypes: [
        { id: 0, name: '昇順' },
        { id: 1, name: '降順' }
      ],
      recordListPopupFlg: false,
      hospitalization: {},
      searchedHospitalizations: [],
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getSpecies: 'species/getDataById'
    }),
    sortedHospitalizations() {
      const list = this.searchedHospitalizations
      switch (this.sortType) {
        case 1: // 飼主名順
          return list.sort((a, b) => {
            if (a.ownerFullNameKana === b.ownerFullNameKana) {
              if (a.ownerFullName === b.ownerFullName) {
                return b.id - a.id
              } else {
                return a.ownerFullName < b.ownerFullName ? -1 : 1
              }
            } else {
              return a.ownerFullNameKana < b.ownerFullNameKana ? -1 : 1
            }
          })
        case 2: // 患者ID順
          return list.sort((a, b) =>
            compareShowId(a.patientShowId, b.patientShowId)
          )
        case 3: //患者名順
          return list.sort((a, b) => {
            if (a.patientNameKana === b.patientNameKana) {
              if (a.patientName === b.patientName) {
                return b.id - a.id
              } else {
                return a.patientName < b.patientName ? -1 : 1
              }
            } else {
              return a.patientNameKana < b.patientNameKana ? -1 : 1
            }
          })
        case 4: // 入院日順
          return list.sort((a, b) => {
            if (a.startDate === 'invalid') {
              return 1
            } else if (b.startDate === 'invalid') {
              return -1
            } else if (a.startDate !== b.startDate) {
              return a.startDate - b.startDate
            } else {
              return a.id - b.id
            }
          })
        case 5: // 退院日順
          return list.sort((a, b) => {
            if (a.endDate === b.endTime) {
              return a.id - b.id
            } else {
              if (a.endDate === 'invalid') {
                return 1
              } else if (b.endDate === 'invalid') {
                return -1
              } else if (a.endDate === '') {
                return 1
              } else if (b.endDate === '') {
                return -1
              } else {
                return a.endDate - b.endDate
              }
            }
          })
        default:
          // 飼主ID順
          return list.sort((a, b) =>
            compareShowId(a.ownerShowId, b.ownerShowId)
          )
      }
    },
    orderedHospitalizations() {
      return this.orderType === 1
        ? this.sortedHospitalizations.slice().reverse()
        : this.sortedHospitalizations.slice()
    },
    minStartDate() {
      return this.endDate
        ? moment(this.endDate, 'YYYYMMDD')
            .add(-1, 'months')
            .toDate()
        : null
    },
    maxEndDate() {
      return this.startDate
        ? moment(this.startDate, 'YYYYMMDD')
            .add(1, 'months')
            .toDate()
        : null
    }
  },

  async created() {
    const startMoment = moment()
    this.startDate = startMoment.format('YYYYMMDD')
    this.defaultStartDate = startMoment.toDate()
    const endMoment = moment()
    this.endDate = endMoment.format('YYYYMMDD')
    this.defaultEndDate = endMoment.toDate()
    await this.searchHospitalizations()
  },

  methods: {
    async searchHospitalizations() {
      this.waitFlg = true
      this.trimSearchText = this.searchText.toLowerCase().replace(/\s+/g, '')
      const res = await this.$store.dispatch('hospitalizations/search', {
        period: { startDate: this.startDate, endDate: this.endDate }
      })
      if (res.result === true) {
        this.makeSearchedHospitalizations(res.hospitalizations)
      } else {
        this.popupFlg = true
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '通信エラーが発生しました。'
      }
      this.waitFlg = false
    },
    makeSearchedHospitalizations(hospitalizations) {
      const displayData = hospitalizations.flatMap(v => {
        const patient = this.getPatient(v.patientId)
        const owner = this.getOwner(patient.ownerId)
        const displayDatum = {
          ...v,
          ownerId: owner.id,
          ownerFullName: `${owner.lastName} ${owner.firstName}`,
          ownerFullNameKana: `${owner.lastNameKana} ${owner.firstNameKana}`,
          ownerShowId: owner.showId,
          patientId: patient.id,
          patientName: patient.name,
          patientNameKana: patient.nameKana,
          patientShowId: patient.showId,
          speciesName: this.getSpecies(patient.speciesId).name,
          displayStartDate:
            v.startDate === ''
              ? ''
              : moment(v.startDate).format('YYYY年MM月DD日（dd）'),
          displayEndDate:
            v.endDate === ''
              ? ''
              : moment(v.endDate).format('YYYY年MM月DD日（dd）'),
          status:
            (v.endDate !== '' && v.endDate > this.endDate) || v.endDate === ''
              ? '入院中'
              : '退院済'
        }
        return displayDatum
      })
      this.searchedHospitalizations = this.filterHospitalizations(displayData)
    },
    filterHospitalizations(displayData) {
      const searchTerm = this.searchText.replace(/\s+/g, '')
      if (searchTerm === '') return displayData
      else {
        return displayData.filter(v => {
          return (
            v.ownerShowId.includes(searchTerm) ||
            v.ownerFullName
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(searchTerm.toLowerCase()) ||
            v.ownerFullNameKana.replace(/\s+/g, '').includes(searchTerm) ||
            kataToHira(v.ownerFullNameKana)
              .replace(/\s+/g, '')
              .includes(searchTerm) ||
            v.patientShowId.includes(searchTerm) ||
            v.patientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            v.patientNameKana.includes(searchTerm) ||
            kataToHira(v.patientNameKana).includes(searchTerm)
          )
        })
      }
    },
    async resetAndGetData() {
      this.searchedHospitalizations = []
      await this.searchHospitalizations()
    },
    inputDate(date, dateType) {
      if (dateType === 'start') {
        this.startDate = date
        if (date > this.endDate) {
          this.endDate = date
          this.$refs.searchArea.endDate = moment(date).toDate()
        } else {
          const maxEndDate = moment(date, 'YYYYMMDD')
            .add(1, 'months')
            .format('YYYYMMDD')
          if (this.endDate > maxEndDate) {
            this.endDate = maxEndDate
            this.$refs.searchArea.endDate = moment(maxEndDate).toDate()
          }
        }
      } else {
        this.endDate = date
        if (date < this.startDate) {
          this.startDate = date
          this.$refs.searchArea.startDate = moment(date).toDate()
        } else {
          const minStartDate = moment(date, 'YYYYMMDD')
            .add(-1, 'months')
            .format('YYYYMMDD')
          if (this.startDate < minStartDate) {
            this.startDate = minStartDate
            this.$refs.searchArea.startDate = moment(minStartDate).toDate()
          }
        }
      }
    },
    inputSearchText(text) {
      this.searchText = text
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    openHospitalizationPopup(hospitalization) {
      this.recordListPopupFlg = true
      this.hospitalization = hospitalization
    },
    closeHospitalizationPopup() {
      this.recordListPopupFlg = false
      this.hospitalization = {}
    }
  }
}
</script>

<style lang="scss" scoped>
.hospitalizations {
  width: 100%;
  overflow: auto;
  > .wrap {
    min-width: 910px;
    padding: 24px 48px;
    text-align: left;
    > .page-title {
      height: 33px;
      font-weight: bold;
      font-size: 20px;
      padding: 20px 0;
      margin-bottom: 30px;
      border-bottom: 1px solid #{$light-grey};
    }
    > .search {
      width: 100%;
      margin-top: 40px;
    }
    > .order {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      > .order-setting {
        display: flex;
        align-items: center;
        > .select-box {
          margin-left: 10px;
        }
      }
    }
    > .list {
      margin-top: 15px;
      ::v-deep .table-body {
        max-height: 630px;
      }
    }
  }
}
</style>
