<template>
  <div class="hospitalization-list-table">
    <div class="table-header">
      <div class="cell"></div>
      <div class="cell owner">
        <div>飼主ID</div>
        <div>飼主名</div>
      </div>
      <div class="cell patient">
        <div>患者ID</div>
        <div>患者名</div>
      </div>
      <div class="cell startDate">入院開始日</div>
      <div class="cell endDate">退院日</div>
    </div>
    <dynamic-scroller
      v-if="displayData.length > 0"
      class="table-body"
      :items="displayData"
      :min-item-size="minItemSize"
      :buffer="buffer"
    >
      <template v-slot="{ item, index, active }">
        <dynamic-scroller-item
          :item="item"
          :active="active"
          :data-index="index"
        >
          <div
            class="row"
            data-test="row"
            :class="{ even: (index + 1) % 2 === 0 }"
            @click="handleRowClick(item)"
          >
            <div
              class="cell"
              data-test="cell"
              :class="{
                blue: item.status === '退院済',
                red: item.status === '入院中'
              }"
            >
              {{ item.status }}
            </div>
            <div
              class="cell owner"
              data-test="cell"
              :title="`${item.ownerShowId}\n${item.ownerFullName}`"
            >
              <div class="text" data-test="text">
                {{ item.ownerShowId }}
              </div>
              <div class="text" data-test="text">
                {{ item.ownerFullName }}
              </div>
            </div>
            <div
              class="cell patient"
              data-test="cell"
              :title="`${item.patientShowId}\n${makePatientNameSpecies(item)}`"
            >
              <div class="text" data-test="text">
                {{ item.patientShowId }}
              </div>
              <div class="text" data-test="text">
                {{ makePatientNameSpecies(item) }}
              </div>
            </div>
            <div class="cell startDate" data-test="cell">
              {{ item.displayStartDate }}
            </div>
            <div class="cell endDate" data-test="cell">
              {{ item.displayEndDate }}
            </div>
          </div>
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
    <div v-else>対象のデータはありませんでした</div>
  </div>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'

export default {
  name: 'HospitalizationListTable',

  components: {
    DynamicScroller,
    DynamicScrollerItem
  },

  props: {
    hospitalizations: { type: Array }
  },

  data() {
    return {
      minItemSize: 68,
      buffer: 10
    }
  },

  computed: {
    displayData() {
      return this.hospitalizations
    }
  },

  methods: {
    makePatientNameSpecies(payment) {
      return payment.speciesName
        ? `${payment.patientName} （${payment.speciesName}）`
        : `${payment.patientName}`
    },
    handleRowClick(item) {
      this.$emit('openPopup', item)
    }
  }
}
</script>

<style lang="scss" scoped>
$box-border-color: #{$light-grey};
.hospitalization-list-table {
  > .table-header {
    font-size: 15px;
    box-sizing: border-box;
    display: flex;
    height: 56px;
    border: 1px solid #{$light-grey};
    background-color: #{$pale};
    > .cell {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 152px;
      flex: 1;
      &.endDate {
        margin-right: 18px;
      }
    }
    > .cell ~ .cell {
      border-left: 1px solid #{$skin};
    }
  }
  > .table-body {
    width: 100%;
    font-size: 15px;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    border: solid #{$light-grey};
    border-bottom: 0px;
    border-width: 0px 1px 1px 1px;
    .row {
      display: flex;
      height: 68px;
      border-bottom: 1px solid #{$light-grey};
      cursor: pointer;
      &.even {
        background-color: #{$white_f7};
      }
      > .cell {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-width: 152px;
        flex: 1;
        padding: 0 20px;
        &.red {
          font-weight: bold;
          color: #{$tomato};
        }
        &.blue {
          font-weight: bold;
          color: #1976d2;
        }
        > .text {
          display: inline-block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    > .row ~ .row {
      border-top: 1px solid #{$light-grey};
    }
  }
}
</style>
