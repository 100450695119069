<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="hospitalization-popup"
      @keyup.esc.stop="closePopup"
      tabindex="-1"
    >
      <popup
        :buttons="['閉じる']"
        @close="closePopup"
        @click-close-mark="closePopup"
      >
        <template v-slot:content>
          <div class="content">
            <div class="title-area">
              <div class="title">入院中カルテ一覧</div>
              <base-button-register
                class="button"
                v-if="endDate === '' && lookOnlyFlg === 0"
                :text="'カルテ登録'"
                @click="goToNewMedicalRecord"
              />
            </div>
            <div class="detail-area">
              <owner-patient-detail
                :owner="owner"
                :patient="patient"
                :addressFlg="false"
                :patientImageFlg="false"
                :lastTreatmentDateFlg="false"
              />
            </div>
            <div class="table">
              <div class="upper-frame">
                <div class="number">{{ displayData.length }}件</div>
              </div>
              <div class="table-header">
                <div class="cell status"></div>
                <div class="cell date">診療日</div>
                <div class="cell disease-name">診断名</div>
              </div>
              <div class="table-body" v-if="displayData.length > 0">
                <div class="row-wrapper">
                  <div
                    class="row"
                    data-test="row"
                    v-for="(record, index) in displayData"
                    :key="`medicalRecord-${index}`"
                    @click="goToMedicalRecord(record.medicalRecordOriginalId)"
                  >
                    <div
                      class="cell status"
                      data-test="cell"
                      :class="{
                        blue: record.status === '退院',
                        red: record.status !== '退院'
                      }"
                    >
                      {{ record.status }}
                    </div>
                    <div class="cell date" data-test="cell">
                      {{ record.displayDate }}
                    </div>
                    <div
                      class="cell disease-name"
                      data-test="cell"
                      :title="record.diseaseName"
                    >
                      <div class="text">{{ record.diseaseName }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>対象のデータはありませんでした</div>
            </div>
          </div>
        </template>
      </popup>
    </div>
  </focus-trap>
</template>

<script>
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import OwnerPatientDetail from '@/components/parts/molecules/OwnerPatientDetail'
import Popup from '@/components/popups/Popup'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { compareMPH } from '@/utils/compare'

export default {
  name: 'HospitalizationPopup',

  components: {
    BaseButtonRegister,
    OwnerPatientDetail,
    FocusTrap,
    Popup
  },

  props: {
    endDate: { type: String },
    startMedicalPaymentOriginalId: { type: Number },
    medicalPaymentOriginalIds: { type: Array },
    endMedicalPaymentOriginalId: { type: Number },
    patientId: { type: Number },
    ownerId: { type: Number }
  },

  data() {
    return {
      owner: {},
      patient: {}
    }
  },

  computed: {
    ...mapGetters({
      getMedicalPaymentByOriginalId: 'medicalPayments/getDataByOriginalId',
      getMedicalRecordById: 'medicalRecords/getDataById',
      getDisease: 'diseases/getDataById',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    displayData() {
      const displayData = this.medicalPaymentOriginalIds.map(originalId => {
        const medicalPayment = this.getMedicalPaymentByOriginalId(originalId)
        const medicalRecord = this.getMedicalRecordById(
          medicalPayment.medicalRecordId
        )
        const disease1 = this.getDisease(medicalPayment.disease1Id)
        const disease2 = this.getDisease(medicalPayment.disease2Id)
        const diseaseName =
          disease1 && disease2
            ? `${disease1.name}, ${disease2.name}`
            : disease1
            ? disease1.name
            : disease2
            ? disease2.name
            : '診断名なし'
        const status =
          originalId === this.endMedicalPaymentOriginalId
            ? '退院'
            : originalId === this.startMedicalPaymentOriginalId
            ? '入院開始'
            : '入院中'
        return {
          id: medicalRecord.originalId,
          medicalRecordOriginalId: medicalRecord.originalId,
          status,
          date: medicalRecord.date,
          startTime: medicalRecord.startTime,
          displayDate: moment(medicalRecord.date, 'YYYYMMDD').format(
            'YYYY年MM月DD日（dd）'
          ),
          diseaseName,
          startHospitalizationFlg: medicalPayment.startHospitalizationFlg,
          inHospitalFlg: medicalPayment.inHospitalFlg,
          endHospitalizationFlg: medicalPayment.endHospitalizationFlg
        }
      })
      displayData.sort((a, b) => this.compare(a, b))
      return displayData
    }
  },

  created() {
    this.owner = { ...this.getOwner(this.ownerId) }
    this.patient = { ...this.getPatient(this.patientId) }
  },

  methods: {
    closePopup() {
      this.$emit('close')
    },
    goToNewMedicalRecord() {
      this.$router.push({
        name: 'medical-record-new',
        params: { ownerId: this.ownerId, patientId: this.patientId }
      })
    },
    goToMedicalRecord(medicalRecordOriginalId) {
      this.$router.push({
        name: 'medical-record-edit',
        params: {
          ownerId: this.ownerId,
          patientId: this.patientId,
          originalId: medicalRecordOriginalId
        }
      })
    },
    compare(a, b) {
      let result = 0
      if (a.date === b.date) {
        if (a.startTime === b.startTime) {
          result = -compareMPH(a, b)
        } else {
          result = a.startTime < b.startTime ? -1 : 1
        }
      } else {
        result = a.date < b.date ? -1 : 1
      }
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.hospitalization-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    box-sizing: border-box;
    > .title-area {
      display: flex;
      justify-content: space-between;
      > .title {
        font-size: 16px;
        font-weight: bold;
        text-align: left;
      }
    }
    > .detail-area {
      margin-top: 20px;
      ::v-deep .owner-patient-detail {
        height: 80px;
      }
    }
    > .table {
      margin-top: 20px;
      overflow-y: auto;
      > .upper-frame {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;
        border: solid #{$light-grey};
        border-width: 1px 1px 0px 1px;
        > .number {
          margin-left: 20px;
          font-size: 13px;
        }
      }
      > .table-header {
        box-sizing: border-box;
        display: flex;
        height: 40px;
        padding-right: 18px;
        border: 1px solid #{$light-grey};
        font-size: 15px;
        background-color: #{$pale};
        > .cell {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          &.status {
            width: 100px;
          }
          &.date {
            width: 160px;
          }
          &.disease-name {
            flex: 1;
          }
        }
        > .cell ~ .cell {
          border-left: 1px solid #{$skin};
        }
      }
      > .table-body {
        box-sizing: border-box;
        width: 100%;
        border: solid #{$light-grey};
        border-width: 0px 1px 1px 1px;
        max-height: 208px;
        overflow-y: scroll;
        overflow-x: hidden;
        > .row-wrapper {
          width: 638px;
          > .row {
            display: flex;
            min-height: 50px;
            padding-right: 17px;
            cursor: pointer;
            &:nth-child(even) {
              background-color: #{$white_f7};
            }
            > .cell {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px;
              text-align: center;
              font-size: 15px;
              &.status {
                width: 100px;
              }
              &.red {
                font-weight: bold;
                color: #{$tomato};
              }
              &.blue {
                font-weight: bold;
                color: #1976d2;
              }
              &.date {
                width: 160px;
              }
              &.disease-name {
                flex: 1;
                width: 0;
                > .text {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
            > .cell + .cell {
              border-left: 1px solid;
              border-color: transparent;
            }
          }
          > .row ~ .row {
            border-top: 1px solid #{$light-grey};
          }
        }
      }
    }
  }
}
</style>
